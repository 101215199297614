
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import NuevoMovimientoModal from "./components/nuevo-movimiento.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import movimientos from "@/core/data/movimientos";
import { IMovimiento } from "@/core/data/movimientos";

export default defineComponent({
  name: "caja",
  components: {
    Datatable,
    NuevoMovimientoModal
  },
  setup() {
    const checkedMovements = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Almacén",
        key: "almacen",
        sortable: true,
      },
      {
        name: "Tipo de Movimiento",
        key: "tipoMovimiento",
        sortable: true,
      },
      {
        name: "Movimiento",
        key: "movimiento",
        sortable: true,
      },
      {
        name: "Destino",
        key: "destino",
        // sortingField: "payment.label",
        sortable: true,
      },
      // {
      //   name: "Fecha",
      //   key: "fecha",
      //   sortable: true,
      // },
      {
        name: "Cliente",
        key: "cliente",
        sortable: true,
      },
      {
        name: "Código",
        key: "codigo",
        sortable: true,
      },
      {
        name: "Unidad",
        key: "unidad",
        sortable: true,
      },
      {
        name: "Cantidad",
        key: "cantidad",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IMovimiento>>(movimientos);
    const initCustomers = ref<Array<IMovimiento>>([]);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Movimientos ", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedMovements.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedMovements.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
    //   tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
    //   if (search.value !== "") {
    //     let results: Array<IMovimiento> = [];
    //     for (let j = 0; j < tableData.value.length; j++) {
    //       if (searchingFunc(tableData.value[j], search.value)) {
    //         results.push(tableData.value[j]);
    //       }
    //     }
    //     tableData.value.splice(0, tableData.value.length, ...results);
    //   }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedMovements,
      deleteFewCustomers,
    };
  },
});
